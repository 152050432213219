export enum ProductFields {
	Id = "id",
	Title = "title",
	Brand = "brand",
	Availability = "availability",
	NewProduct = "newProduct",
	Type = "type",
	Style = "style",
	BlxGroup = "blxGroup",
	Locations = "locations",
	ABV = "abv",
	IBU = "ibu",
	SRM = "srm",
	Color = "color",
	Slug = "slug",
	Updated = "updated",
	Created = "created",
}
